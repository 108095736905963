import React, { useState } from 'react';
import styled from 'styled-components';

import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import AnimatedRoute from '../components/AnimatedRoute';
import withTracker from '../wrappers/withTracker';
import { userIntroGetStarted } from '../actions/segment';

import { palette } from '../theme/palette';
import OshiNextButton from '../components/OshiNextButton';
import { IntroPageSigninBtnPressed } from '../actions/segment';

const title = `It's quick and easy to get the care you need.`;
const subHeader = `Over the next few minutes, we'll help you schedule your first online visit
  with a GI specialist. We'll also make sure Oshi's integrated GI care is in-network with your
  insurance.`;

function Intro({ IntroPageSigninBtnPressed }) {
  const handleNext = () => {
    history.push('name-step');
  };

  const onSignInClick = () => {
    IntroPageSigninBtnPressed();
    history.push('/signin');
  };

  const [animationDirection, setAnimationDirection] = useState('mount');
  const history = useHistory();

  const getBanner = () => {
    return <BannerText>Get seen right away by a GI provider.</BannerText>;
  };

  return (
    <PageContainer>
      <AnimatedRoute
        nextRoute='/intro'
        title={title}
        banner={getBanner()}
        animationDirection={animationDirection}
        setAnimationDirection={setAnimationDirection}
        showProgressBar={false}
        isRightLayout={true}
        showProviderBanner={true}
        onSignInClick={onSignInClick}
      >
        <IntroContainer>
          <IntroContent>{subHeader}</IntroContent>
          <NextBtnContainer>
            <NextBtn>
              <OshiNextButton
                buttonTitle={
                  <span>
                    Let&apos;s go <RightIconArrow>→</RightIconArrow>
                  </span>
                }
                disabled={false}
                onClick={handleNext}
                styles={{ height: '50px' }}
                containerStyles={{ marginTop: 0 }}
              />
            </NextBtn>
          </NextBtnContainer>
        </IntroContainer>
      </AnimatedRoute>
    </PageContainer>
  );
}

const PageContainer = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const NextBtnContainer = styled.div`
  width: 100%;
  margin-top: auto;
  justify-content: center;
  margin-top: auto;
`;

const NextBtn = styled.div`
  padding: 16px 24px;
  
  @media only screen and (min-width: 769px) {
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 152px;
    padding: 0px;
  }
`

const RightIconArrow = styled.span`
  font-size: 1em;
  padding-left: 10px;

  @media only screen and (min-width: 769px) {
    padding-left: 8px;
  }
`;

const IntroContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  height: 100%;
  align-items: center;

  @media only screen and (min-width: 769px) {
    max-width: 600px;
    height: 100%;
    gap: 42px;
    flex: 0;
  }
`;

const IntroContent = styled.div`
  padding: 24px;
  font-family: 'Commissioner', sans-serif;
  font-size: 20px;
  font-weight: 300;
  line-height: 36px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: ${palette.darkPrimaryText};
  flex: 1;

  @media only screen and (min-width: 769px) {
    flex: 0;
    padding: 0px;
  }

  @media only screen and (min-width: 1136px) {
    font-size: 24px;
  }
`;

const BannerText = styled.div`
  color: ${palette.navy500};
  font-size: 14px;
  font-weight: 600;
  font-family: Commissioner;
  line-height: 21px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  height: 100%;
  vertical-align: middle;
`;

export default connect(null, {
  userIntroGetStarted,
  IntroPageSigninBtnPressed,
})(withTracker(Intro, 'Signup - Intro - Page View'));
